main {
    width: 100%;
    position: relative;
    height: 100%;
}

.vjs-poster {
    background-color: #FFF !important;
}

.player-container{
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: start;
}

.player {
    max-width: calc(177.778vh);
    height: calc(56.25vw);
    position: relative;
    max-height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    text-rendering: optimizeLegibility;
    color: #fff;
    line-height: normal;
    font-size: 10px;
    overflow: visible;
    border-collapse: separate;
    user-select: none;
}

.vjs-menu-button-popup .vjs-menu {
    left: -5.5em !important;
}

.blob {
    background: rgba(255, 255, 255, 0.6);
    border-radius: 50%;
    margin: 10px;
    height: 15px;
    width: 15px;
    transform: scale(1);
}

.blob-live {
    background: rgba(255, 82, 82, 1);
    border-radius: 50%;
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
    margin: 10px;
    height: 15px;
    width: 15px;
    transform: scale(1);
    animation: pulse-red 2s infinite;
}

@keyframes pulse-red {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
    }
}
